import httpClient from "@/api/httpClient";

const LIST_CATEGORY_PUBLIC = "/api/contents/publics/categories";
const LIST_CATEGORY_LIBRARY = "/api/contents/libraries/categories";

const getListCategoryPublicContent = (_params) => httpClient.get(LIST_CATEGORY_PUBLIC, {
    params: _params
});
const getDetailCategoryPublicContent = (_id) => httpClient.get(LIST_CATEGORY_PUBLIC + "/" + _id, {})
const getListCategoryLibraryContent = (_params) => httpClient.get(LIST_CATEGORY_LIBRARY, {
    params: _params
});
const getDetailCategoryLibraryContent = (_id) => httpClient.get(LIST_CATEGORY_LIBRARY + "/" + _id, {})
const createCategoryPublicContent = payload =>
    httpClient.post(LIST_CATEGORY_PUBLIC, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
const createCategoryLibraryContent = payload =>
    httpClient.post(LIST_CATEGORY_LIBRARY, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
const updateCategoryPublicContent = (payload, id) =>
    httpClient.post(LIST_CATEGORY_PUBLIC + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const updateCategoryLibraryContent = (payload, id) =>
    httpClient.post(LIST_CATEGORY_LIBRARY + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const deleteCategoryPublicContent = (_id) => httpClient.delete(LIST_CATEGORY_PUBLIC +"/"+ _id);
const deleteCategoryLibraryContent = (_id) => httpClient.delete(LIST_CATEGORY_LIBRARY +"/"+ _id);
export{
    getListCategoryPublicContent,
    getListCategoryLibraryContent,
    createCategoryPublicContent,
    createCategoryLibraryContent,
    deleteCategoryPublicContent,
    deleteCategoryLibraryContent,
    updateCategoryPublicContent,
    updateCategoryLibraryContent,
    getDetailCategoryPublicContent,
    getDetailCategoryLibraryContent
}
